import React, { useState } from 'react'
import { Select, Alert } from 'antd'
import { Col, Row } from 'antd'
import VIPP1Assessment from './VIPP1Assessment.json'

import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'

const { Option } = Select

const P1TYPES = VIPP1Assessment.p1types
const LOCATIONS = VIPP1Assessment.locations
const INSTRUCTORS = VIPP1Assessment.instructor
const CARTYPES = VIPP1Assessment.carTypes
const AssementAttempts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const RenderInfo = (hptstate,info) => {
    
    if (info) {

        return <div>
            <p>{info}</p>
            <p>Please continue with the booking but you must need to complete HPT ASAP before your actual P1 assessment.</p>
            <p> <a href="https://www.stategrowth.tas.gov.au/pp/hpt">https://www.stategrowth.tas.gov.au/pp/hpt</a> </p>
        </div>
    }
     
    
    
    return <div><p>Please continue with the booking but you must need to complete HPT ASAP before your actual P1 assessment.</p>
        <p> <a href="https://www.stategrowth.tas.gov.au/pp/hpt">https://www.stategrowth.tas.gov.au/pp/hpt</a> </p>
    </div>
}
const VIPP1AssessmentBooking = ({ data }) => {
    const [instructor, setInstructor] = useState()
    const [eventurl, setCalendlyURL] = useState()
    const [carType, setCarType] = useState()
    const [p1attempt, setAttempt] = useState()
    const [attemptVisible, setAttemptDisplay] = useState(false)
    const [location, setLocation] = useState()
    const [hptstate, setHptState] = useState()
    const [info, setInfo] = useState()

    const onChangeInstructor = (value, option, index) => {
        setInstructor(value)
        updateInfo({
            instructor: value,
            carType,
            p1attempt,
            location,
            hptstate
        })
    }
    const onChangeCarType = (value, option, index) => {
        setCarType(value)
        updateInfo({
            carType: value,
            instructor,
            p1attempt,
            location,
            hptstate
        })
    }
    const onChangeLocation = (value, option, index) => {
        setLocation(value)
        const location = LOCATIONS.find(l => l.key == value)
        if(!location.valid){
            setAttemptDisplay(false)
        }else {
            setAttemptDisplay(true)
        }

        updateInfo({
            location: value,
            instructor,
            p1attempt,
            carType,
            hptstate

        })
    }
    const onHPTChange = (value, option, index) => {
        setHptState(value)
        
        updateInfo({
            location,
            instructor,
            p1attempt,
            carType,
            hptstate:value

        })
    }
    const onChangeP1Attempt = (value, option, index) => {
        setAttempt(value)
        updateInfo({
            p1attempt: value,
            instructor,
            carType,
            location,
            hptstate
        })
    }

    const updateInfo = data => {
        console.log('clisn', data, instructor, carType, p1attempt)
        
        if (data?.location && data?.instructor && data?.carType && data?.hptstate) {
            const locationlocal = LOCATIONS.find(l => l.key == data.location)
            setInfo(locationlocal.info);
            let attempt = data.p1attempt || 1;
            console.log(attempt,'s')
            let object = P1TYPES.find(l => l.key == data.carType)
            if (attempt == 1 && data?.carType == 'vipcar') {
                object = P1TYPES.find(
                    l => l.key == data.carType && l.attempt == 1,
                )
            }
            if (attempt > 1 && data?.carType == 'vipcar') {
                object = P1TYPES.find(
                    l => l.key == data.carType && l.attempt > 1,
                )
            }


            if (locationlocal.valid && object && object?.calendly) {
                console.log(location,'locationss',locationlocal,carType,instructor)
                if(locationlocal[data.carType] && locationlocal[data.carType][instructor]){
                   setCalendlyURL(locationlocal[data.carType][instructor])  
                }else {
                     setCalendlyURL(object.calendly[data?.instructor])
                }
               
            }
            if (!locationlocal.valid){
                setCalendlyURL('')

            }


        }


    }

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => console.log(e),
    })

    console.log(eventurl, 'rend')
    return (
        <div className="vip-booking py-12 sm:p-11">
            <h2 className={'p-5 text-center'}>Book P1 Assessment</h2>

            <Row>
                <Col span={6}>
                    <p>Please Select Instructor:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Select Instructor'}
                        style={{ width: '100%' }}
                        onChange={onChangeInstructor}
                    >
                        <Option value="">Select one</Option>
                        {INSTRUCTORS.map(i => {
                            return (
                                <Option key={i.key} value={i.key}>
                                    {i.label}
                                </Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <p>Please Select Car:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Please Select Car'}
                        style={{ width: '100%' }}
                        onChange={onChangeCarType}
                    >
                        <Option value="">Select one</Option>
                        {CARTYPES.map(i => {
                            return (
                                <Option key={i.key} value={i.key}>
                                    {i.label}
                                </Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <p>Please specify pick-up location:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Please specify pick-up'}
                        style={{ width: '100%' }}
                        onChange={onChangeLocation}
                    >
                        <Option value="">Select one</Option>
                        {LOCATIONS.map(i => {
                            return (
                                <Option key={i.key} value={i.key}>
                                    {i.key}
                                </Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <p>Have you  done HPT test? </p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Have you  done HPT test'}
                        style={{ width: '100%' }}
                        onChange={onHPTChange}
                    >
                        <Option value="">Select one</Option>
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                  
                    </Select>
                </Col>
            </Row>
            {info && hptstate=='yes' && (
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        {info && (
                            <Alert
                                className={'m-3'}
                                message={info}
                                type="info"
                            />
                        )}
                    </Col>
                </Row>
            )}
            {hptstate=='no' && (
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        <Alert
                            className={'m-3'}
                            message={RenderInfo(hptstate,info)}
                            type="info"
                        />
                    </Col>
                </Row>
            )}
            {eventurl && (
                <InlineWidget
                    url={eventurl}
                    styles={{
                        height: '800px',
                        width: '100%',
                    }}
                />
            )}
        </div>
    )
}

export default VIPP1AssessmentBooking
